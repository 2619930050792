import React, { useEffect } from "react"
import { Link, graphql } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import { Helmet } from 'react-helmet'
import netlifyIdentity from 'netlify-identity-widget'

import BigButton from '../components/BigButton/BigButton'
import Header from '../components/Header/Header'
import Layout from '../components/Layout/Layout'
import SocialMediaSection from "../components/Home/SocialMediaSection/SocialMediaSection"
import Updates from '../components/Home/UpdatesSection/UpdatesSection'

import '../styles/styles.css'
import '../styles/home.css'

const Home = ({ data }) => {
  useEffect(() => {
    netlifyIdentity.init({})
  }, [])

  return (
    <Layout title="Home">
      <Helmet>
        <script type="text/javascript">{`
          document.querySelector('[data-tool="verify"]').classList.add('hidden')
          document.querySelector('[data-tool="register"]').classList.add('hidden')
          document.querySelector('[data-tool="locate"]').classList.add('hidden')
        `}
        </script>
      </Helmet>
      <Header background={data.markdownRemark.frontmatter.headerImage.photo} alt={data.markdownRemark.frontmatter.headerImage.altText}>{data.markdownRemark.frontmatter.heading}</Header>
      <div className="main home">
        <div className="flex-row cta-row">
          {data.markdownRemark.frontmatter.buttons.map(button => (
            <BigButton link={button.link} photo={button.photo} name={button.name} external={button.external} key={button.link} />
          ))}
        </div>
        <h2>What We're About</h2>
        <div className="flex-row about-row">
          <ReactMarkdown children={data.markdownRemark.frontmatter.about.blurb} />
          <div className="video-container">
            {data.markdownRemark.frontmatter.about.video && ((data.markdownRemark.frontmatter.about.video.includes("youtube") || data.markdownRemark.frontmatter.about.video.includes("youtu.be"))
              ? <iframe src={data.markdownRemark.frontmatter.about.video} frameBorder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title="Video"></iframe>
              : <video src={data.markdownRemark.frontmatter.about.video} controls />
            )}
          </div>
        </div>
        <div className="flex-row btn-row">
          <Link to="/about/what-were-about" className="cta-btn">See More About MS Votes</Link>
        </div>

        {data.allMarkdownRemark.edges.length !== 0 ? <Updates posts={data.allMarkdownRemark.edges} /> : null}
        <SocialMediaSection />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`query HomeQuery {
  markdownRemark(frontmatter: {title: {eq: "Home"}}) {
    frontmatter {
      heading
      headerImage {
        photo {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        altText
      }
      about {
        blurb
        video
      }
      buttons {
        photo {
          childImageSharp {
            gatsbyImageData(transformOptions: {fit: COVER, cropFocus: ATTENTION})
          }
        }
        name
        link
        external
      }
    }
  }
  allMarkdownRemark (
    sort: { fields: [frontmatter___publishDate], order: DESC }
    filter: { frontmatter: { newsArticle: {eq: false}}}
  ) {
    edges {
      node {
        id
        rawMarkdownBody
        timeToRead
        excerpt(pruneLength: 280)
        fields {
          slug
        }
        frontmatter {
          title
          newsOutlet
          publishDate
          author
          photo {
            photo {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
            altText
          }
        }
      }
    }
  }
}
`

export default Home