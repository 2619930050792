import React from 'react'
import { TwitterTimelineEmbed } from 'react-twitter-embed'

import * as styles from './SocialMediaSection.module.css'

const SocialMediaSection = () => {

  return (
    <>
      <h2>What We're Talking About</h2>
      <div className={styles.social_row} >
        <div className={styles.social_module}>
          <TwitterTimelineEmbed
            sourceType="profile"
            screenName="msvotes"
            options={{
              height: "700px",
              width: "250px"
            }}
          />
        </div>
        <div className={styles.social_module} id="facebookFeed">
          <iframe title="Mississippi Vote's Facebook Feed" src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fmsvotes&tabs=timeline&width=250&height=750&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" width="250" height="700" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
        </div>
      </div>
    </>
  )
}

export default SocialMediaSection