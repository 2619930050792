import React from 'react'
import { Link } from 'gatsby'

import Post from './Post/Post'

const UpdatesSection = (props) => {
  let posts = props.posts.filter(post => post.node.rawMarkdownBody.length > 0)
  let filteredPosts = posts.slice(0, 3)

  return (
    <>
      <h2>Blog</h2>
      {filteredPosts.map(post => (
        <Post
          key={post.node.id}
          title={post.node.frontmatter.title}
          excerpt={post.node.excerpt}
          date={post.node.frontmatter.publishDate}
          photo={post.node.frontmatter.photo.photo}
          link={`/blog${post.node.fields.slug}`}
          author={post.node.frontmatter.author}
          altText={post.node.frontmatter.photo.altText} />
      ))}
      {posts.length > 3 ? (
        <div className="flex-row btn-row">
          <Link to="/blog/" className="cta-btn">See More Blog Posts</Link>
        </div>
      ) : null}
    </>
  )
}

export default UpdatesSection